import { CATALOG_PAGE_TYPES } from '~/utils/constants'

type Query = {
  path: string
  catalogId?: string
  catalogType?: string
  catalogPath?: string
}

const handlePageData = async ({ to, path, locale, logger }) => {
  const pageQuery = { path: to.fullPath }

  let query: Query = {
    path,
  }

  const pageCacheKey = `router_page_${to.fullPath}`
  const { data } = useNuxtData(pageCacheKey)

  if (!data.value) {
    logger.info('fetching data', pageCacheKey)
    data.value = await $fetch('/api/router/page', { query: pageQuery }).catch((e) => ({
      pageType: 'ERROR',
      statusCode: e.statusCode,
      statusMessage: e.statusMessage,
    }))
  } else {
    logger.info('using cached data', pageCacheKey)
  }

  if (data.value?.redirect_code) {
    const redirectUrl = `/${locale.value}/${data.value?.relative_url}`
    if (redirectUrl !== to.fullPath) {
      logger.info('Redirecting to', redirectUrl, 'with redirect code: ', data.value?.redirect_code)
      navigateTo(redirectUrl)
      return { data: {}, query: { path: '' } }
    }
  }

  to.meta.type = data.value?.pageType

  if (CATALOG_PAGE_TYPES.includes(data.value?.type)) {
    query.catalogId = data.value?.id
    query.catalogType = data.value?.type
    query.catalogPath = data.value?.path

    // If there's more than one facet, fallback to the category page
    if (data.value?.facets?.length > 1) {
      path = path.replace(/\/[^/]*$/, '')
      query.path = path
    }
  }

  return { data: data.value, query }
}

export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useAppLogger('middleware/bloomreach.ts')
  const { locale } = useLocale()
  // Remove trailing slash and anchored hashes
  let path = to.fullPath.replace(/\/$/, '').replace(/(#.{0,500}(?=\?)|#.{0,500}$)/, '')

  // MYJE-7002 redirect page
  if (path.includes('/01/')) return

  const { data: routerPageData, query } = await handlePageData({ to, path, locale, logger })

  let searchRouteData = null

  const brPageCacheKey = `bloomreach_page_${path}`
  const { data: page } = useNuxtData(brPageCacheKey)

  if (to.path.includes('/catalogsearch')) {
    searchRouteData = {
      type: 'SEARCH',
      relative_url: 'catalogsearch/result',
      query: JSON.parse(JSON.stringify(to.query)),
    }
  }
  if (!page.value) {
    logger.info('fetching data', brPageCacheKey)

    page.value = await $fetch('/api/bloomreach/page', { query }).catch((e) => {
      to.meta.data = {
        pageType: 'ERROR',
        statusCode: e.statusCode,
        statusMessage: e.statusMessage,
      }
      to.meta.type = 'ERROR'
    })
  } else {
    logger.info('using cached data', brPageCacheKey)
  }

  to.meta.page = page.value
  to.meta.routeData = searchRouteData || routerPageData || {}
  to.meta.path = routerPageData?.path || ''

  // If the type hasn't been set, set it based on the route
  if (!to.meta.type) {
    to.meta.type = to.path.includes('/catalogsearch') ? 'SEARCH' : page.value?.pageType || 'BLOOMREACH'
  }
})
